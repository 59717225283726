import { Alert, Checkbox, FormControlLabel, LinearProgress, Tooltip } from '@mui/material';
import { format, parse } from 'date-fns';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { AddButton, TooltipText } from '../styledComponents/GlobalStyle';
import Select from 'react-select';
import Breadcrumb from '../components/Common/Breadcrumb';
import SageApp from '../components/Integrations/SageApp';
import XeroApp from '../components/Integrations/XeroApp';
import AddOrgModal from '../components/Organisations/Modal';
import OrganisationMetrics from '../components/Organisations/OrganisationMetrics';
import { itemToOptions } from '../components/utils/GlobalFunctions';
import { BetaTag } from '../components/Common/ReusableComponents/Reusable';
import {
  ShowTooltip,
  alertMessage,
  getOwnershipHierarchy,
  saveOrgValidationSchema,
  setOrgOwnershipHierarchy,
} from '../components/utils/OrganisationUtils';
import { flexBetweenCenter, selectStyles } from '../components/utils/ReactSelectStyles';
import {
  googleAPIKeyTooltipContent,
  orgIndustryTooltipContent,
  reportingYearEndIssueTooltipContent,
} from '../components/utils/TooltipContent';
import { Actions as ADMIN_ACTIONS } from '../store/actions/adminActions';
import { Actions } from '../store/actions/organisationActions';

const Organisation = ({
  organisations,
  saveOrganisation,
  selectedOptions,
  organisation,
  updateOrganisation,
  setCurrentOrganisation,
  orgModal,
  adminOrganisations,
  branding,
  cyfAdmin,
  consultantAdmin,
  whiteLabelAdmin,
  toggleAddOrgModal,
  hasPaymentSystem,
  isWhiteLabelApp,
  isDirectUser,
  adminUsers,
  consultantClient,
  getAllItemsFromDB,
  reportingYearEndIssue,
  wlSettings,
  stripeSubscription,
  stripeCustomerId,
  orgOptions,
  addOns,
  industriesSubcategories,
  filterOptions,
  updateParentOptions,
  orgDetails,
  isAllowedChild,
  currentOrganisation,
  getOrgHierarchy,
  getOrgParents,
  countries,
  currencies,
  features,
  availableFeatures,
}) => {
  const { tooltipBackground, tooltipText, primaryColor, textColor } = branding.colors || {};
  const { headers } = branding.fonts?.[0].font || {};
  const history = useHistory();
  let { id } = useParams();
  const [editing, setEditing] = useState(id === 'new');
  const [editedState, setEditedState] = useState(null);
  const [validationError, setValidationError] = useState(null);
  const [alertStatus, setAlertStatus] = useState(false);
  const [parentOrgAlert, setParentOrgAlert] = useState(false);
  const [ownershipHierarchy, setOwnershipHierarchy] = useState({
    whiteLabelAdmin: false,
    whiteLabelClient: false,
    consultantAdmin: false,
    consultantClient: false,
    directUser: true,
  });

  // Set default values
  if (cyfAdmin) selectedOptions['accountType'] = selectedOptions['adminOpt'];
  else if (isWhiteLabelApp) {
    selectedOptions['accountType'] = _.filter(selectedOptions['adminOpt'], { label: 'White Label' });
    selectedOptions['subscriptionPlan'] = _.filter(selectedOptions['subscriptionPlan'], { label: 'Annual' });
  } else if (consultantAdmin || consultantClient) {
    selectedOptions['accountType'] = _.filter(selectedOptions['adminOpt'], { label: 'Consultant' });
    selectedOptions['subscriptionPlan'] = _.filter(selectedOptions['subscriptionPlan'], { label: 'Annual' });
  } else selectedOptions['accountType'] = _.filter(selectedOptions['adminOpt'], { label: 'Direct User' });

  useEffect(() => {
    if (id === 'new') {
      setCurrentOrganisation({ id: null, new: true });
      setEditing(true);
      setEditedState({
        name: '',
        country: '',
        currency: '',
        consultantId: 'NONE',
        industry: '',
        reportingDate: '',
        reportingYearEndDay: '',
        reportingYearEndMonth: '',
        subscriptionEndDate: '',
        subscriptionPlan: selectedOptions?.['subscriptionPlan']?.[0]?.value,
        accountType: selectedOptions?.['accountType']?.[0]?.value,
        parentOrganisation: null,
        googleApiKey: '',
        whiteLabelId: '',
        status: 'Active',
      });
    } else {
      let org;
      if (adminOrganisations) {
        const gatherOrganisations = organisations && adminOrganisations && [...(organisations ?? []), ...adminOrganisations];
        org = id && gatherOrganisations && gatherOrganisations.find((item) => item.id === id);
      } else if (organisations) {
        org = id && organisations && organisations.find((item) => item.id === id);
      }
      updateOrganisation(org);
    }
    //eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    let newOwnershipHierarchy = { ...ownershipHierarchy };
    newOwnershipHierarchy = setOrgOwnershipHierarchy(newOwnershipHierarchy, organisation);
    setOwnershipHierarchy(newOwnershipHierarchy);
    //eslint-disable-next-line
  }, [organisation]);

  useEffect(() => {
    if (cyfAdmin && !selectedOptions?.['userId']) {
      // Fetch all Users to Show Them on Members Dropdown
      getAllItemsFromDB('profile');
    }
    getAllItemsFromDB('addOns');
    getAllItemsFromDB('industrySubcategory');
    if (currentOrganisation) {
      getOrgHierarchy(currentOrganisation);
      getOrgParents(currentOrganisation);
    }
    //eslint-disable-next-line
  }, []);

  const updateField = (e) => {
    const newEditedState = { ...editedState };
    newEditedState[e.target.name] = e.target.value;
    setEditedState(newEditedState);
  };

  const handleCheckboxField = (e) => {
    const { name, checked } = e.target || {};
    const newEditedState = { ...editedState };
    if (['directUser', 'whiteLabelClient', 'whiteLabelAdmin', 'consultantAdmin', 'consultantClient'].includes(name)) {
      const newOwnershipHierarchy = { ...ownershipHierarchy };
      // Set all to false and then set the current one to true
      Object.keys(newOwnershipHierarchy).map((x) => (newOwnershipHierarchy[x] = false));
      newOwnershipHierarchy[name] = checked;
      setOwnershipHierarchy(newOwnershipHierarchy);
      if (['directUser', 'whiteLabelClient'].includes(name)) {
        // If Org is Direct User Org is Linked to CompareYourFootprint -> set whiteLabelId to 0 and consultantId to NONE
        // If Org is a WL Client, set whiteLabelId to 0 and then in the followig input set the whiteLabelId
        newEditedState['whiteLabelId'] = '0';
        newEditedState['consultantId'] = 'NONE';
        newEditedState['accountType'] = name === 'directUser' ? 'Direct User' : 'White Label';
      } else if (['consultantClient'].includes(name)) {
        // If it's a Consultant Client link the org to CYF (whiteLabelId to 0) and then in the following input set the consultantId
        newEditedState['whiteLabelId'] = '0';
        newEditedState['accountType'] = 'Consultant';
      } else if (['whiteLabelAdmin'].includes(name)) {
        // If it's a WL Admin Org set whiteLabelId to integer 1 and on the backend set the whiteLabelId to the orgID
        newEditedState['consultantId'] = 'NONE';
        newEditedState['whiteLabelId'] = 1;
        newEditedState['accountType'] = 'White Label';
      } else if (['consultantAdmin'].includes(name)) {
        // If it's a Consultant Admin Org set consultantId to integer 1 and on the backend set the consultantId to the orgID
        newEditedState['consultantId'] = 1;
        newEditedState['whiteLabelId'] = '0';
        newEditedState['accountType'] = 'Consultant';
      }
    } else if (['accountType'].includes(name)) {
      // Consultant or White Label Admin set org as Client Test Organisation
      if (checked) {
        newEditedState['accountType'] = 'Client Test Organisation';
        toggleAddOrgModal(true, { ...editedState }, 'clientTestOrg');
      } else {
        if (isWhiteLabelApp) newEditedState['accountType'] = 'White Label';
        else if (consultantAdmin || consultantClient) newEditedState['accountType'] = 'Consultant';
      }
    }
    setEditedState(newEditedState);
  };

  const isAdmin = cyfAdmin || whiteLabelAdmin || false;
  const saveOrg = async () => {
    await saveOrgValidationSchema
      .validate(
        {
          name: editedState['name'],
          reportingYearEndDay: editedState['reportingYearEndDay'],
          industry: editedState['industry'],
          country: editedState['country'],
          currency: editedState['currency'],
          industrySubcategory:
            isAdmin && industriesSubcategories?.includes(editedState?.['industry']) && editedState['industrySubcategory'],
        },
        { abortEarly: false }
      )
      .then(() => {
        if (id === 'new' && isDirectUser && hasPaymentSystem && !consultantClient) {
          // Show Subscription Modal for all direct users with payment system except consultant clients
          toggleAddOrgModal(true, { ...editedState, hasSubscription: false }, 'addingOrg');
          setEditing(false);
        } else if (id === 'new' && (adminUsers || consultantClient || (isDirectUser && !hasPaymentSystem))) {
          // Don't show subscription modal for all admins, consultant clients and direct users without payment system
          setEditing(false);
          saveOrganisation({ ...editedState, hasSubscription: true }, history);
        } else if (id !== 'new') {
          // Editing Organisation
          setEditing(false);
          saveOrganisation({ ...organisation, ...editedState }, history);
        } else if (industriesSubcategories?.includes(editedState?.['industry'])) {
          setValidationError([{ path: 'industrySubcategory', message: 'Please select an industry sub-category' }]);
        }
        setValidationError(null);
      })
      .catch((e) => setValidationError(e.inner));
  };

  let selectedItem = {};
  selectedOptions &&
    Object.keys(selectedOptions).map(
      (x) =>
        (selectedItem[x] =
          selectedOptions[x] &&
          selectedOptions[x].find((item) => {
            return editedState && editedState?.[x] && item.value === editedState?.[x];
          }))
    );

  orgOptions['industrySubcategory'].options = filterOptions(editedState?.['industry']);

  if (organisation?.id !== id && id !== 'new') {
    return (
      <div className='row' style={{ fontFamily: headers }}>
        <div className='col-lg-12'>
          <section className='panel'>
            <header className='panel-heading'>Organisation Loading</header>
            <LinearProgress color='inherit' />
          </section>
        </div>
      </div>
    );
  }

  return (
    <>
      <Breadcrumb
        items={[
          { label: 'Organisations', link: '/organisations' },
          {
            label: (organisation && organisation?.name) || 'New',
            link: `/organisations/${organisation ? organisation?.id : 'new'}`,
          },
        ]}
      />

      <div className='row' style={{ fontFamily: headers }}>
        <div className='col-lg-12'>
          <section className='panel'>
            <aside className='profile-nav alt'>
              <section className='panel'>
                <header className='panel-heading' id='orgHeading'>
                  Your organisation - {editedState && editedState.name ? editedState.name : organisation?.name}
                  {editing ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Tooltip
                        placement='top'
                        title={<TooltipText>Save Organisation</TooltipText>}
                        arrow
                        componentsProps={{
                          tooltip: { sx: { backgroundColor: tooltipBackground, color: tooltipText } },
                          arrow: { sx: { color: tooltipBackground } },
                        }}>
                        <i
                          className='far fa-save'
                          id='save-button'
                          style={{ fontSize: 25, marginRight: 20 }}
                          onClick={saveOrg}
                        />
                      </Tooltip>
                      <Tooltip
                        placement='top'
                        title={<TooltipText>Cancel</TooltipText>}
                        arrow
                        componentsProps={{
                          tooltip: { sx: { backgroundColor: tooltipBackground, color: tooltipText } },
                          arrow: { sx: { color: tooltipBackground } },
                        }}>
                        <i
                          className='fa fa-ban'
                          onClick={() => {
                            setEditing(false);
                            setAlertStatus(false);
                            setParentOrgAlert(false);
                            organisation?.id
                              ? history.push(`/organisations/${organisation?.id}`)
                              : history.push('/organisations');
                          }}
                          style={{ fontSize: 25 }}
                        />
                      </Tooltip>
                    </div>
                  ) : (
                    <i
                      className='fa fa-pencil fa-lg'
                      id='start-editing'
                      style={{ float: 'right', fontSize: 25 }}
                      onClick={() => {
                        setEditing(true);
                        setAlertStatus(false);
                        setParentOrgAlert(false);
                        //updateParentOptions(orgDetails?.reportingYearEndDay, orgDetails?.reportingYearEndMonth);
                        let editedState = {};
                        Object.keys(organisation).forEach((key) => {
                          if (key === 'reportingDate') {
                            editedState['reportingDate'] = new Date(
                              `2020-${organisation['reportingYearEndMonth'] || 12}-${
                                organisation['reportingYearEndDay'] || 31
                              }`
                            );
                          } else editedState[key] = organisation?.[key] || null;
                        });
                        setEditedState(editedState);
                      }}
                    />
                  )}
                </header>

                <ul className='nav nav-pills nav-stacked' id='organisation-table'>
                  {Object.keys(orgOptions)
                    .filter((key) => {
                      const wlAdmin = whiteLabelAdmin && 'White Label Admin';
                      const CYFAdmin = cyfAdmin && 'CYF Admin';
                      const consultAdmin = consultantAdmin && 'Consultant Admin';
                      if (wlAdmin) {
                        return orgOptions[key]?.exclusiveFor?.includes(wlAdmin) || !orgOptions[key].exclusiveFor;
                      } else if (CYFAdmin) {
                        return orgOptions[key]?.exclusiveFor?.includes(CYFAdmin) || !orgOptions[key].exclusiveFor;
                      } else if (consultAdmin) {
                        return orgOptions[key]?.exclusiveFor?.includes(consultAdmin) || !orgOptions[key].exclusiveFor;
                      } else return !orgOptions[key]?.exclusiveFor;
                    })
                    .filter((key) => {
                      //check if feature is enabled by default, if not check if user has access to the feature
                      if (orgOptions[key]?.feature) {
                        let feature = features?.find((f) => f.featureKey === orgOptions[key]?.feature);
                        if (feature?.enabledByDefault === true) {
                          return orgOptions[key];
                        } else if (feature?.enabledByDefault === false && availableFeatures?.includes(feature?.featureKey)) {
                          return orgOptions[key];
                        }
                      } else {
                        return orgOptions[key];
                      }
                    })
                    .map((key) => {
                      const type = orgOptions[key]?.type;
                      const feature = orgOptions[key]?.feature;
                      const isBeta = features?.find((f) => f.featureKey === feature)?.isBeta;
                      const betaTag = feature && isBeta === true && <BetaTag backgroundColor={textColor} color={'#fff'} />;
                      let value, datePickerOpenToDate, datePickerDateFormat, tooltipContent;
                      let disabled = false;

                      // Do not show when adding new organisation
                      if (id === 'new') {
                        if (['hasSubscription', 'userId', 'paymentMethod'].includes(key)) return null;
                      }

                      // Logic to handle the orgOwnership field
                      if (['orgOwnership'].includes(key)) {
                        if (!ownershipHierarchy?.['whiteLabelClient'] && !ownershipHierarchy?.['consultantClient']) {
                          // orgOwnership field should only be visible when whiteLabelClient or consultantClient
                          // are set on the organisation ownership hierarchy
                          return null;
                        } else {
                          // Define the options for the orgOwnership field
                          orgOptions[key].options = adminOrganisations
                            ?.filter((x) => {
                              if (ownershipHierarchy?.['whiteLabelClient']) {
                                // filter all WL Admin Orgs
                                return x.whiteLabelId === x?.id;
                              } else if (ownershipHierarchy?.['consultantClient']) {
                                // filter all Consultant Admin Orgs
                                return x.consultantId === x?.id;
                              }
                              return {};
                            })
                            .map(itemToOptions);
                          value = orgOptions[key].options?.find(
                            (org) =>
                              org.value === editedState?.['whiteLabelId'] || org.value === editedState?.['consultantId']
                          );
                        }
                      }

                      // Handle Logic to show values on the UI when not on editing mode
                      if (!editing) {
                        if (type === 'select') {
                          if (key === 'parentOrganisation') {
                            value =
                              organisation?.[key] === true || organisation?.[key] === null
                                ? organisation?.name
                                : adminUsers
                                ? _.find(adminOrganisations, { id: organisation?.[key] })?.name
                                : _.find(organisations, { id: organisation?.[key] })?.name;
                          } else if (['hasSubscription'].includes(key)) {
                            // For Boolean values show Yes or No
                            value = organisation?.[key] === true ? 'Yes' : 'No';
                          } else if (['orgOwnership'].includes(key)) {
                            value = adminOrganisations?.find(
                              (x) => x.id === organisation.whiteLabelId || x.id === organisation.consultantId
                            )?.name;
                          } else if (['industrySubcategory'].includes(key)) {
                            value = _.find(selectedOptions?.[key], { value: organisation?.[key] })?.label || 'N/A';
                          } else {
                            value = _.find(selectedOptions?.[key], { value: organisation?.[key] })?.label || 'N/A';
                          }
                        } else if (type === 'multiSelect') {
                          if (key === 'addOns') {
                            const addOnIds = organisation?.[key];
                            const values = addOnIds?.map((id) => {
                              return addOns?.find((addon) => addon.id === id)?.name;
                            });
                            value = values?.join(', ');
                          } else if (key === 'features') {
                            const featureIds = organisation?.[key];
                            const values = featureIds?.map((featureKey) => {
                              return features?.find((feature) => feature.featureKey === featureKey)?.name;
                            });
                            value = values?.join(', ');
                          }
                        } else if (type === 'datePicker') {
                          if (key.includes('subscriptionEndDate')) {
                            value = organisation?.subscriptionEndDate
                              ? format(parse(organisation?.subscriptionEndDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')
                              : '';
                          } else if (key.includes('reportingYearEnd')) {
                            value = `${organisation?.reportingYearEndDay} / ${organisation?.reportingYearEndMonth}`;
                          }
                        } else if (['ownershipHierarchy'].includes(key)) {
                          value = getOwnershipHierarchy(ownershipHierarchy);
                        } else value = organisation?.[key];
                      }
                      // Handle Logic to show values on the UI when on EDITING mode
                      else {
                        if (type === 'datePicker') {
                          if (key.includes('subscriptionEndDate')) {
                            value =
                              editedState && editedState?.subscriptionEndDate && editedState?.subscriptionEndDate !== ''
                                ? parse(editedState?.subscriptionEndDate, 'yyyy-MM-dd', new Date())
                                : '';
                            datePickerDateFormat = 'dd/MM/yyyy';
                          } else if (key.includes('reportingYearEndDay')) {
                            value = editedState && editedState.reportingDate;
                            datePickerOpenToDate = new Date(new Date().getFullYear(), 11, 31);
                            datePickerDateFormat = 'dd/MM';
                          }
                        }
                      }

                      // Handle Logic to Disable Inputs
                      if (['subscriptionEndDate'].includes(key)) {
                        // Disable datePicker if there is a customer and a subscription on Stripe for the Organisation
                        if (stripeCustomerId && stripeSubscription) disabled = true;
                      } else if (key.includes('reportingYearEndDay')) {
                        // Disable for none CYF Admins
                        if (reportingYearEndIssue && !cyfAdmin && id !== 'new') disabled = true;

                        const hasParent =
                          organisation?.parentOrganisation != null &&
                          organisation?.parentOrganisation != true &&
                          organisation?.parentOrganisation != organisation?.id;
                        //Checks if organisation is a parent of any other organisation except its own
                        const isParent = adminOrganisations?.find(
                          (x) => x.parentOrganisation === organisation?.id && x.id !== organisation?.id
                        );
                        const disableRYE = key === 'reportingYearEndDay' && (hasParent || isParent) && id !== 'new';
                        if (disableRYE) disabled = true;
                      }

                      // Handle Tooltip Content Logic
                      if (key.includes('reportingYearEndDay')) {
                        tooltipContent = reportingYearEndIssueTooltipContent();
                      } else if (key.includes('industry')) {
                        tooltipContent = orgIndustryTooltipContent(wlSettings);
                      } else if (key.includes('googleApiKey')) {
                        tooltipContent = googleAPIKeyTooltipContent(wlSettings);
                      } else if (key.includes('organisationalHierarchy')) {
                        tooltipContent = 'Setup child companies under this parent company';
                        // 'You can only select parent organisations with the same Reporting Year End, if you would like to change the Reporting Year End of any organisation please contact support.';
                      }
                      const subIndustryCheck =
                        key === 'industrySubcategory' && !editing && !organisation?.industrySubcategory;

                      return (
                        <li className={key} key={key}>
                          <div
                            style={{
                              ...flexBetweenCenter,
                              border: _.find(validationError, { path: key })?.message ? '3px solid red' : 'none',
                              display:
                                (key === 'industrySubcategory' &&
                                  editing &&
                                  !industriesSubcategories?.includes(editedState?.['industry'])) ||
                                subIndustryCheck
                                  ? 'none'
                                  : key === 'organisationalHierarchy' && id === 'new'
                                  ? 'none'
                                  : 'flex',
                              padding: '10px 15px',
                            }}>
                            <div style={{ minWidth: 150, display: 'flex', alignItems: 'center' }}>
                              <i className={orgOptions[key].icon} style={{ paddingRight: 20 }} />
                              {orgOptions[key].label}
                              <div>{betaTag}</div>
                              <ShowTooltip content={validationError && _.find(validationError, { path: key })?.message} />
                              {orgOptions?.[key].tooltip && (
                                <Tooltip
                                  title={<TooltipText>{tooltipContent}</TooltipText>}
                                  placement='right'
                                  arrow
                                  componentsProps={{
                                    tooltip: {
                                      sx: { bgcolor: tooltipBackground, color: tooltipText },
                                    },
                                    arrow: { sx: { color: tooltipBackground } },
                                  }}>
                                  <i
                                    className='fa fa-question-circle'
                                    style={{ fontSize: 18, color: primaryColor, marginLeft: 10 }}
                                  />
                                </Tooltip>
                              )}
                            </div>
                            {editing ? (
                              <>
                                {type === 'text' && (
                                  <input
                                    name={key}
                                    className='form_input'
                                    onChange={updateField}
                                    value={editedState?.[key] || ''}
                                  />
                                )}
                                {type === 'select' && (
                                  <>
                                    <span>
                                      {parentOrgAlert && ['parentOrganisation'].includes(key) && (
                                        <Alert
                                          severity='warning'
                                          onClose={() => setParentOrgAlert(false)}
                                          style={{ marginBottom: '3px', maxWidth: '30vw' }}>
                                          <span style={{ color: 'red' }}>
                                            <b>
                                              {
                                                'The selected parent organisation is a child of the current organisation, please select another parent Organisation or contact support'
                                              }
                                            </b>
                                          </span>
                                        </Alert>
                                      )}

                                      <Select
                                        className={key}
                                        value={selectedItem?.[key] || value || ''}
                                        options={orgOptions?.[key].options}
                                        styles={selectStyles}
                                        isClearable
                                        menuPlacement={'auto'}
                                        onChange={(selected) => {
                                          if (selected?.value === 'Client Test Organisation') {
                                            toggleAddOrgModal(true, { ...editedState }, 'clientTestOrg');
                                          }
                                          const newEditedState = { ...editedState };
                                          if (['orgOwnership'].includes(key)) {
                                            if (ownershipHierarchy?.['whiteLabelClient']) {
                                              // Org is a WL Client set whiteLabelId
                                              newEditedState['whiteLabelId'] = selected?.value;
                                              newEditedState['consultantId'] = 'NONE';
                                            } else if (ownershipHierarchy?.['consultantClient']) {
                                              // Org is a consultant client set consultantId
                                              newEditedState['consultantId'] = selected?.value;
                                              newEditedState['whiteLabelId'] = '0';
                                            }
                                          } else newEditedState[key] = selected?.value;
                                          if (key === 'industry') {
                                            newEditedState['industrySubcategory'] = null;
                                          }
                                          if (key === 'parentOrganisation') {
                                            if (organisation?.id === null) {
                                              if (selected?.value === undefined) {
                                                newEditedState[key] = null;
                                              } else {
                                                newEditedState[key] = selected?.value;
                                                newEditedState['parentOrgObj'] = {
                                                  startDate: '2000-01-01',
                                                  endDate: '2030-12-31',
                                                  owner: selected?.value,
                                                  percentage: '100',
                                                };
                                              }
                                            } else {
                                              if (isAllowedChild(organisation?.id, selected?.value)) {
                                                setParentOrgAlert(false);
                                                if (selected?.value === undefined) {
                                                  newEditedState[key] = null;
                                                } else {
                                                  newEditedState[key] = selected?.value;
                                                  newEditedState['parentOrgObj'] = {
                                                    org: organisation?.id,
                                                    startDate: '2000-01-01',
                                                    endDate: '2030-12-31',
                                                    owner: selected?.value,
                                                    percentage: '100',
                                                  };
                                                }
                                              } else {
                                                setParentOrgAlert(true);
                                                newEditedState[key] = null;
                                              }
                                            }
                                          }
                                          setEditedState(newEditedState);
                                        }}
                                      />
                                    </span>
                                  </>
                                )}
                                {type === 'datePicker' && (
                                  <span style={{ width: '30vw', float: 'right', display: 'block' }}>
                                    {alertStatus && ['reportingYearEndDay'].includes(key) && (
                                      <Alert
                                        severity='warning'
                                        onClose={() => setAlertStatus(false)}
                                        style={{ marginBottom: '3px' }}>
                                        <span style={{ color: 'red' }}>
                                          <b>{alertMessage}</b>
                                        </span>
                                      </Alert>
                                    )}
                                    <>
                                      <DatePicker
                                        id={key}
                                        disabled={disabled}
                                        showMonthDropdown
                                        dateFormat={datePickerDateFormat}
                                        openToDate={datePickerOpenToDate}
                                        selected={value}
                                        style={{ width: `${reportingYearEndIssue ? '100%' : ''}`, minHeigth: '4vw' }}
                                        className='form-control form_input'
                                        onChange={(date) => {
                                          if (!date) return;
                                          const newEditedState = { ...editedState };
                                          if (['subscriptionEndDate'].includes(key)) {
                                            newEditedState[key] = format(date, 'yyyy-MM-dd');
                                          } else if (['reportingYearEndDay'].includes(key)) {
                                            setAlertStatus(true);
                                            newEditedState['reportingDate'] = date;
                                            newEditedState['reportingYearEndDay'] = format(date, 'dd');
                                            newEditedState['reportingYearEndMonth'] = format(date, 'MM');
                                            newEditedState['parentOrganisation'] = null;
                                            /* updateParentOptions(
                                              newEditedState['reportingYearEndDay'],
                                              newEditedState['reportingYearEndMonth']
                                            ); */
                                          }
                                          setEditedState(newEditedState);
                                        }}
                                      />
                                    </>
                                  </span>
                                )}
                                {type === 'checkbox' && (
                                  <div style={{ width: '30vw' }}>
                                    {orgOptions[key].options.map((i, key) => {
                                      return (
                                        <FormControlLabel
                                          key={key}
                                          control={
                                            <Checkbox
                                              size='small'
                                              name={i.name}
                                              checked={
                                                ownershipHierarchy?.[i.value] ||
                                                editedState?.[i.name] === 'Client Test Organisation' ||
                                                false
                                              }
                                              onChange={handleCheckboxField}
                                            />
                                          }
                                          label={i.title}
                                        />
                                      );
                                    })}
                                  </div>
                                )}
                                {type === 'multiSelect' && (
                                  <Select
                                    className={key}
                                    value={editedState?.[key]?.map((id) =>
                                      orgOptions?.[key].options?.find((x) => x.value === id)
                                    )}
                                    options={orgOptions?.[key]?.options}
                                    styles={selectStyles}
                                    isClearable
                                    isMulti
                                    menuPlacement={'auto'}
                                    onChange={(selected) => {
                                      const newEditedState = { ...editedState };
                                      if (selected == null) {
                                        newEditedState[key] = [];
                                      } else {
                                        newEditedState[key] = selected?.map((x) => x.value);
                                      }
                                      setEditedState(newEditedState);
                                    }}
                                  />
                                )}
                                {type === 'button' && (
                                  <AddButton
                                    onClick={() => {
                                      history.push(`/organisations/${organisation.id}/organisationHierarchy`);
                                    }}>
                                    Manage
                                  </AddButton>
                                )}
                              </>
                            ) : type === 'button' ? (
                              <AddButton
                                onClick={() => history.push(`/organisations/${organisation.id}/organisationHierarchy`)}>
                                Manage
                              </AddButton>
                            ) : (
                              <span style={{ float: 'right' }}>{value}</span>
                            )}
                          </div>
                        </li>
                      );
                    })}
                </ul>
              </section>
            </aside>
          </section>

          {organisation?.id && <OrganisationMetrics id={id} />}

          {organisation?.id && (
            <section className='panel'>
              <header className='panel-heading'>Integration Settings</header>
              <XeroApp id={id} />
              <SageApp id={id} />
            </section>
          )}

          {orgModal?.status === true && <AddOrgModal />}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { cyfAdmin, consultantAdmin, whiteLabelAdmin } = state.profile?.details || {};
  const { profile, addOns, industrySubcategory } = state?.admin?.adminPortal || {};
  const { hasPaymentSystem, whiteLabelId } = state?.whiteLabelAdmin || {};
  const { list } = state?.organisation || {};
  const adminOrganisations = state.admin?.organisations;
  const availableFeatures = state.organisation?.details?.features;
  const isAdminOpt = [
    'Direct User',
    'Benchmark',
    'Consultant',
    'Demo',
    'Green Element Org',
    'CYF Test Organisation',
    'Client Test Organisation',
    'White Label',
  ];
  const adminUsers = cyfAdmin || whiteLabelAdmin || consultantAdmin || false;
  const consultantClient = list?.filter((x) => x.consultantId !== 'NONE');
  const { stripe: { stripeCustomerId, stripeSubscriptions } = {} } = state?.payment || {};
  const orgDetails = state?.organisation?.details;
  const organisations = adminUsers ? adminOrganisations : state.organisation?.list;
  const currencies = state?.organisation?.currencies;
  const countries = state?.organisation?.countries;
  const features = state?.features;

  const isAllowedChild = (childId, parentId) => {
    //ChildId is always currentOrg
    //Child should not be allowed to be a child if it is a parent of the parentID
    let parentOrg = organisations?.find((x) => x.id === parentId);
    while (parentOrg?.parentOrganisation) {
      if (parentOrg?.parentOrganisation === childId) return false;
      if (parentOrg?.parentOrganisation === parentOrg?.id) return true; //We reached top level org, child can be assigned
      parentOrg = organisations?.find((x) => x.id === parentOrg?.parentOrganisation);
    }
    return true;
  };

  /* const updateParentOptions = (endDay, endMonth) => {
    orgOptions.parentOrganisation.options = adminUsers
      ? adminOrganisations
          ?.filter((org) => org.reportingYearEndDay === endDay && org.reportingYearEndMonth === endMonth)
          ?.map((org) => ({ value: org.id, label: org.name }))
      : state?.organisation?.list
          ?.filter((org) => org.reportingYearEndDay === endDay && org.reportingYearEndMonth === endMonth)
          ?.map((org) => ({ value: org.id, label: org.name }));
    selectedOptions.parentOrganisation = orgOptions.parentOrganisation.options;
  }; */

  const selectedOptions = {
    industry: _.sortBy(state?.organisation?.industries?.map(itemToOptions), ['label']),
    country: _.sortBy(state?.organisation?.countries?.map(itemToOptions), ['label']),
    status: ['Active', 'Inactive'].map((x) => ({ label: x, value: x == 'Active' ? 'Active' : 'Inactive' })),
    currency: currencies
      ?.filter((x) => x.hideInUI !== true)
      .map((x) => ({ label: `${x.symbol} (${x.isoCode})`, value: x.id })),
    //Shows only organisations which have the same reporting year end date and month
    parentOrganisation: adminUsers
      ? adminOrganisations
          ?.filter(
            (org) =>
              org.reportingYearEndDay === orgDetails?.reportingYearEndDay &&
              org.reportingYearEndMonth === orgDetails?.reportingYearEndMonth
          )
          ?.map((org) => ({ value: org.id, label: org.name }))
      : state?.organisation?.list
          ?.filter(
            (org) =>
              org.reportingYearEndDay === orgDetails?.reportingYearEndDay &&
              org.reportingYearEndMonth === orgDetails?.reportingYearEndMonth
          )
          ?.map((org) => ({ value: org.id, label: org.name })),
    whiteLabelId: ['Yes', 'No'].map((x) => ({ label: x, value: x == 'Yes' ? 1 : '0' })),
    subscriptionPlan: ['Annual', 'Unlimited', 'License'].map((x) => ({ label: x, value: x })),
    userId: profile?.map((x) => ({ label: `${x.firstName} ${x.lastName}`, value: x.id })),
    hasSubscription: ['Yes', 'No'].map((x) => ({ label: x, value: x === 'Yes' ? true : false })),
    adminOpt: isAdminOpt.map((x) => ({ label: x, value: x })),
    adminOrg: adminOrganisations?.map((x) => itemToOptions(x)),
    addOns: addOns?.map((x) => itemToOptions(x)),
    features: features?.map((x) => ({ label: x?.name, value: x?.featureKey })),
    industrySubcategory: industrySubcategory?.map((x) => itemToOptions(x)),
  };

  const filterOptions = (industry) => {
    return industrySubcategory?.filter((x) => x.industry === industry).map(itemToOptions);
  };

  const industriesSubcategories = _.uniq(
    industrySubcategory?.map((item) => {
      return item.industry;
    })
  );

  const orgOptions = {};
  orgOptions.name = {
    label: 'Organisation Name',
    name: 'name',
    type: 'text',
    icon: 'fa fa-cogs',
    placeholder: 'Organisation Name',
  };
  orgOptions.organisationalHierarchy = {
    label: 'Organisational Hierarchy',
    name: 'organisationalHierarchy',
    type: 'button',
    icon: 'fa-solid fa-sitemap',
    tooltip: true,
    feature: 'ORGANISATION_HIERARCHY',
  };
  orgOptions.status = {
    label: 'Status',
    name: 'status',
    type: 'select',
    icon: 'fa fa-adjust',
    placeholder: 'Status',
    options: selectedOptions?.['status'],
    exclusiveFor: ['CYF Admin'],
  };
  orgOptions.userId = {
    label: 'Member',
    name: 'member',
    type: 'select',
    icon: 'fa fa-user',
    placeholder: 'Status',
    options: selectedOptions?.['userId'],
    exclusiveFor: ['CYF Admin'],
  };
  orgOptions.reportingYearEndDay = {
    label: 'Reporting Year End',
    name: 'reportingYearEndDay',
    type: 'datePicker',
    icon: 'fa fa-calendar-alt',
    placeholder: 'Reporting Year End',
    tooltip: true,
  };
  orgOptions.industry = {
    label: 'Industry',
    name: 'industry',
    type: 'select',
    icon: 'fa fa-industry',
    placeholder: 'Industry',
    options: selectedOptions?.['industry'],
    tooltip: true,
  };
  orgOptions.industrySubcategory = {
    label: 'Industry Sub-Category',
    name: 'industrySubcategory',
    type: 'select',
    icon: 'fa fa-industry',
    placeholder: 'Industry Sub-Category',
    options: selectedOptions?.['industrySubcategory'],
    exclusiveFor: ['CYF Admin', 'White Label Admin'],
  };
  orgOptions.country = {
    label: 'Country',
    name: 'country',
    type: 'select',
    icon: 'fa-solid fa-globe',
    placeholder: 'Country',
    options: selectedOptions?.['country'],
  };
  orgOptions.currency = {
    label: 'Currency',
    name: 'currency',
    type: 'select',
    icon: 'fa fa-money',
    placeholder: 'Currency',
    options: selectedOptions?.['currency'],
  };
  orgOptions.vatNumber = {
    label: 'VAT Number',
    name: 'vatNumber',
    type: 'text',
    icon: 'fa fa-money',
    placeholder: 'VAT Number',
  };
  orgOptions.isinNumber = {
    label: 'ISIN Number',
    name: 'isinNumber',
    type: 'text',
    icon: 'fa fa-money',
    placeholder: 'ISIN Number',
  };
  orgOptions.leiCode = {
    label: 'LEI Code',
    name: 'leiCode',
    type: 'text',
    icon: 'fa fa-money',
    placeholder: 'LEI Code',
  };
  orgOptions.accountType = {
    label: 'Account type',
    name: 'accountType',
    type: !cyfAdmin ? 'checkbox' : 'select',
    icon: 'far fa-user-circle',
    placeholder: 'Account type',
    options: !cyfAdmin
      ? [{ title: 'Test Organisation', name: 'accountType', value: 'Client Test Organisation' }]
      : selectedOptions?.['adminOpt'],
    exclusiveFor: ['CYF Admin', 'White Label Admin', 'Consultant Admin'],
  };
  orgOptions.subscriptionEndDate = {
    label: 'Subscription End Date',
    name: 'subscriptionEndDate',
    type: 'datePicker',
    icon: 'far fa-clock',
    placeholder: 'Subscription End Date',
    exclusiveFor: ['CYF Admin'],
  };
  orgOptions.subscriptionPlan = {
    label: 'Subscription Plan',
    name: 'subscriptionPlan',
    type: 'select',
    icon: 'fas fa-ruler-combined',
    placeholder: 'Subscription Plan',
    options: selectedOptions?.['subscriptionPlan'],
    exclusiveFor: ['CYF Admin'],
  };
  /* orgOptions.paymentMethod = {
    label: 'Payment Method',
    name: 'paymentMethod',
    type: 'text',
    icon: 'far fa-credit-card',
    placeholder: 'Payment Method',
    exclusiveFor: ['CYF Admin'],
  }; */
  orgOptions.googleApiKey = {
    label: 'Google Api Key',
    name: 'googleApiKey',
    type: 'text',
    icon: 'far fa-credit-card',
    placeholder: 'Google Api Key',
    tooltip: true,
  };
  orgOptions.ownershipHierarchy = {
    label: 'Client Administrator Type',
    name: 'ownershipHierarchy',
    type: 'checkbox',
    icon: 'far fa-user-circle',
    placeholder: 'Client Administrator Type',
    options: [
      { title: 'White Label Admin', name: 'whiteLabelAdmin', value: 'whiteLabelAdmin' },
      { title: 'White Label Client', name: 'whiteLabelClient', value: 'whiteLabelClient' },
      { title: 'Consultant Admin', name: 'consultantAdmin', value: 'consultantAdmin' },
      { title: 'Consultant Client', name: 'consultantClient', value: 'consultantClient' },
      { title: 'Direct User', name: 'directUser', value: 'directUser' },
    ],
    exclusiveFor: ['CYF Admin'],
  };
  orgOptions.orgOwnership = {
    label: 'Client Administrator',
    name: 'ownershipHierarchy',
    type: 'select',
    icon: 'far fa-user-circle',
    placeholder: 'Client Administrator',
    options: [],
    exclusiveFor: ['CYF Admin'],
  };
  orgOptions.hasSubscription = {
    label: 'Has Subscription Active?',
    name: 'hasSubscription',
    type: 'select',
    icon: 'far fa-credit-card',
    placeholder: 'Has Subscription Active?',
    options: selectedOptions?.['hasSubscription'],
    exclusiveFor: ['CYF Admin'],
  };
  orgOptions.addOns = {
    label: 'Add Ons',
    name: 'addOns',
    type: 'multiSelect',
    icon: 'fa-solid fa-puzzle-piece',
    placeholder: 'Add Ons',
    options: selectedOptions?.['addOns'],
    exclusiveFor: ['CYF Admin'],
  };
  orgOptions.features = {
    label: 'Features',
    name: 'features',
    type: 'multiSelect',
    icon: 'fa-solid fa-flask',
    placeholder: 'Features',
    options: selectedOptions?.['features'],
    exclusiveFor: ['CYF Admin'],
  };

  return {
    organisation: state?.organisation?.details,
    organisations: state?.organisation?.list,
    adminOrganisations,
    branding: state.branding,
    cyfAdmin,
    consultantAdmin,
    whiteLabelAdmin,
    orgModal: state.organisation.modal,
    isWhiteLabelApp: whiteLabelId !== '0',
    isDirectUser: !cyfAdmin && !whiteLabelAdmin && !consultantAdmin,
    consultantClient: consultantClient?.length > 0,
    adminUsers,
    hasPaymentSystem: hasPaymentSystem,
    reportingYearEndIssue: state?.organisation?.reportingYearEndIssue,
    wlSettings: state.whiteLabelAdmin,
    selectedOptions,
    stripeSubscription: stripeSubscriptions?.[0],
    stripeCustomerId,
    orgOptions,
    addOns,
    industriesSubcategories,
    filterOptions,
    //updateParentOptions,
    orgDetails,
    isAllowedChild,
    currentOrganisation: state.currentOrganisation,
    countries,
    currencies,
    features,
    availableFeatures,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveOrganisation: (organisation, history) => dispatch(Actions.saveOrganisation(organisation, history)),
  setCurrentOrganisation: (organisation) => dispatch(Actions.setCurrentOrganisation(organisation)),
  updateOrganisation: (organisation) => dispatch(Actions.updateOrganisation(organisation)),
  toggleAddOrgModal: (status, item, action) => dispatch(Actions.toggleModal(status, item, action)),
  toggleModal: (status, item, action) => dispatch(ADMIN_ACTIONS.toggleModal(status, item, action)),
  getAllItemsFromDB: (table) => dispatch(ADMIN_ACTIONS.getAllItemsFromDB(table)),
  getOrgHierarchy: (orgId) => dispatch(Actions.getOrgHierarchy(orgId)),
  getOrgParents: (orgId) => dispatch(Actions.getOrgParents(orgId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Organisation);
