import { addDays } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Actions } from '../../store/actions/organisationActions';
import { AddButton } from '../../styledComponents/GlobalStyle';

const today = new Date();

function Modal({
  showModal,
  toggleModal,
  organisationDelete,
  deleteOrganisationMetric,
  action,
  saveOrganisation,
  organisationItem,
  modalItem,
  disableUser,
  orgId,
  creationDate,
  reinviteUser,
  deleteOrganisationHierarchy,
}) {
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [buttonsTitle, setButtonsTitle] = useState({ confirm: '', cancel: '' });
  const history = useHistory();

  const handleClose = () => {
    toggleModal(false, null);
    if (action === 'addingOrg') {
      history.push('/organisations');
    }
  };

  const handleAction = async () => {
    setLoading(true);
    if (action === 'addingOrg') {
      await saveOrganisation(organisationItem, history);
      toggleModal(false, null);
    } else if (action === 'disableUser') {
      await disableUser(modalItem.sub || modalItem.email);
    } else if (action === 'reinviteUser') {
      await reinviteUser(modalItem);
    } else if (action === 'deleteOrgMetric') {
      deleteOrganisationMetric(organisationDelete.item.id);
    } else if (action === 'deleteOrgHierarchy') {
      deleteOrganisationHierarchy(organisationItem.id);
      console.log('deleteOrgHierarchy');
    } else if (action === 'orgMissingCompulsoryData') {
      history.push(`/organisations/${orgId}`);
      toggleModal(false, null);
    } else if (action === 'clientTestOrg') {
      toggleModal(false, null);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (action === 'addingOrg') {
      setModalTitle('Do you wish to add a new organisation?');
      setButtonsTitle({ confirm: 'Yes, I want to subscribe', cancel: 'No, I don’t want to subscribe' });
    } else if (action === 'disableUser') {
      setModalTitle('Are you sure you want to delete this user?');
      setButtonsTitle({ confirm: 'Yes, delete the user', cancel: 'Cancel' });
    } else if (action === 'reinviteUser') {
      setModalTitle('Are you sure you want to reinvite this user?');
      setButtonsTitle({ confirm: 'Yes, reinvite', cancel: 'Cancel' });
    } else if (action === 'deleteOrgMetric') {
      setModalTitle('Are you sure you wish to delete this organisation metric?');
      setButtonsTitle({ confirm: 'Yes, delete this data', cancel: 'Cancel' });
    } else if (action === 'deleteOrgHierarchy') {
      setModalTitle('Are you sure you wish to delete this child organisation?');
      setButtonsTitle({ confirm: 'Yes, delete this child', cancel: 'Cancel' });
    } else if (action === 'orgMissingCompulsoryData') {
      setModalTitle('Your organisation is missing some compulsory data');
      setButtonsTitle({ confirm: 'Go to Organisation Details', cancel: '' });
    } else if (action === 'clientTestOrg') {
      setModalTitle('You are about to set this organisation to a Client Test Organisation');
      setButtonsTitle({ confirm: 'Close', cancel: '' });
    }
  }, [action]);

  const expireDay = useMemo(() => {
    if (action === 'clientTestOrg') {
      if (!creationDate) {
        return addDays(new Date(), 30);
      } else {
        return addDays(new Date(creationDate), 30);
      }
    } else {
      return null;
    }
  }, [action, creationDate]);

  const ModelContent = () => {
    if (action === 'addingOrg') {
      return (
        <p>
          By adding a new organisation to your account, you'll need to subscribe the added organisation to have access to our
          application.
        </p>
      );
    } else if (action === 'disableUser') {
      return <p>By confirming you are going to delete this user account.</p>;
    } else if (action === 'reinviteUser') {
      return <p>By confirming you are going to reinvite this user.</p>;
    } else if (action === 'deleteOrgMetric') {
      return <p>This action cannot be undone.</p>;
    } else if (action === 'deleteOrgHierarchy') {
      return <p>This action cannot be undone.</p>;
    } else if (action === 'orgMissingCompulsoryData') {
      return (
        <p>
          Your Organisation has some missing compulsory Data. <br /> To keep using the application please go to your
          organisation details page and fill the compuslory fields missing.
        </p>
      );
    } else if (action === 'clientTestOrg') {
      return (
        <p>
          Please note, by selecting this option, this organisation will only be retained for{' '}
          <b>30 days from the date it was created</b>. The organisation and all associated test data will be{' '}
          <b>
            permanently deleted at 11pm{' '}
            {expireDay > today ? (
              `on ${expireDay?.toLocaleDateString('en-GB')}`
            ) : (
              <span style={{ color: 'red' }}>TODAY</span>
            )}
          </b>
          . You can check how long the organisation has remaining by looking in the bottom left of the dashboard page (with
          the organisation selected).
        </p>
      );
    }
  };

  if (!showModal) return null;

  return (
    <div className='modal' style={{ display: 'block', background: 'rgba(0,0,0,.5)' }}>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <button onClick={handleClose} className='close'>
              x
            </button>
            <h4 className='modal-title'>{modalTitle}</h4>
          </div>
          <div className='modal-body'>
            <ModelContent />
            <AddButton
              onClick={handleAction}
              className='btn btn-danger'
              disabled={loading}
              style={{ marginRight: 10, display: !buttonsTitle.confirm && 'none' }}>
              {loading && <i className='fa fa-spin fa-spinner' style={{ marginRight: 5 }} />}
              {buttonsTitle?.confirm}
            </AddButton>
            <button onClick={handleClose} className='btn btn-success' style={{ display: !buttonsTitle.cancel && 'none' }}>
              {buttonsTitle?.cancel}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { status: showModal, item: modalItem, action } = state.organisation?.modal || {};
  const { hasSubscriptionActive, id: orgId, creationDate } = state.organisation?.details || {};
  return {
    showModal,
    modalItem,
    organisationDelete: state.organisation?.modal,
    action,
    organisationItem: state.organisation?.modal?.item,
    hasSubscriptionActive,
    orgId,
    creationDate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleModal: (status, item) => dispatch(Actions.toggleModal(status, item)),
    disableUser: (item) => dispatch(Actions.disableUser(item)),
    reinviteUser: (item) => dispatch(Actions.reinviteUser(item)),
    deleteOrganisationMetric: (id) => dispatch(Actions.deleteOrganisationMetric(id)),
    saveOrganisation: (organisation, history) => dispatch(Actions.saveOrganisation(organisation, history)),
    deleteOrganisationHierarchy: (id) => dispatch(Actions.deleteOrganisationHierarchy(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
